<template>
  <LastBetsHeader v-if="!mobile" is-sport />
  <LastBetsWrapper
    :is-loading="isLoading"
    :have-elements="!!props.bets?.length"
    :skeleton-amount="mobile ? 8 : 10"
    data-t="sport-last-bets-t2wz"
  >
    <LastSportBet
      v-for="bet in props.bets"
      :key="bet.betId"
      :bet="bet"
      :mobile="mobile"
    />
  </LastBetsWrapper>
</template>

<script setup lang="ts">
import LastBetsHeader from '../LastBetsHeader.vue'
import type { SportLastBet } from '../../types'
import LastBetsWrapper from '../LastBetsWrapper.vue'
import LastSportBet from '../LastSportBet.vue'

const props = withDefaults(
  defineProps<{
    bets: SportLastBet[] | null
    isLoading: boolean
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)
</script>
