import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import type { IconName } from '@st/ui/components/StIcon/types'

type TabId = 'sport' | 'casino' | 'big-bets-casino' | 'big-bets-sport'

interface Tab {
  id: TabId
  label: string
  icon: IconName
}

export function useLastBets(mode: 'main' | 'sport' | 'casino') {
  const { settings } = storeToRefs(useSettingsStore())

  const { t } = useI18n()

  const tabsByMode = computed<Tab[]>(() => {
    switch (mode) {
      case 'sport':
        return [
          {
            id: 'sport',
            label: t('bets.all'),
            icon: 'all-sports',
          },
          {
            id: 'big-bets-sport',
            label: t('lastBets.onlyBig'),
            icon: 'coin-stack-1-solid',
          },
        ]
      case 'casino':
        return [
          {
            id: 'casino',
            label: t('bets.all'),
            icon: 'cherry-solid' as const,
          },
          {
            id: 'big-bets-casino',
            label: t('lastBets.onlyBig'),
            icon: 'coin-stack-1-solid' as const,
          },
        ]
      default:
        return [
          {
            id: 'casino',
            label: t('tapBar.casino'),
            icon: 'cherry-solid',
          },
          {
            id: 'sport',
            label: t('tapBar.sport'),
            icon: 'all-sports',
          },
          {
            id: 'big-bets-casino',
            label: t('lastBets.bigBets'),
            icon: 'coin-stack-1-solid',
          },
        ]
    }
  })

  const enabledTabIds = computed<Record<TabId, boolean>>(() => ({
    casino: !!settings.value?.frontCasinoLastBetsEnabled,
    sport: !!settings.value?.frontLastBetsEnabled,
    'big-bets-casino': !!settings.value?.frontCasinoLastBigBetsEnabled,
    'big-bets-sport': !!settings.value?.frontLastBigBetsEnabled,
  }))

  const tabs = computed(() =>
    tabsByMode.value.filter((tab) => enabledTabIds.value[tab.id]),
  )

  const isVisible = computed(() => !!tabs.value?.length)

  return {
    tabs,
    isVisible,
  }
}
