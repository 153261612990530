<template>
  <div class="header-wrapper" :class="{ sport: isSport }">
    <div class="header">
      <div v-if="props.isSport" class="header-item game" data-t="sport-header">
        {{ t('lastBets.event') }}
      </div>
      <div v-else class="header-item game" data-t="casino-header">
        {{ t('casinoBetsHistory.game') }}
      </div>

      <div class="header-item player" data-t="player">
        {{ t('casinoTournament.player') }}
      </div>

      <div v-if="isSport" class="header-item date" data-t="sport-date">
        {{ t('lastBets.time') }}
      </div>
      <div v-else class="header-item date" data-t="casino-date">
        {{ t('casinoBetsHistory.date') }}
      </div>

      <div
        v-if="!isSport"
        class="header-item bet-amount"
        data-t="casino-bet-amount"
      >
        {{ t('casinoBetsHistory.betAmount') }}
      </div>

      <div v-if="isSport" class="header-item bet-rate" data-t="sport-rate">
        {{ t('bets.rate') }}
      </div>
      <div v-else class="header-item bet-rate" data-t="casino-rate">
        {{ t('casinoBetsHistory.betOdds') }}
      </div>

      <div v-if="isSport" class="header-item bet-result" data-t="sport-amount">
        {{ t('casinoBetsHistory.betAmount') }}
      </div>
      <div v-else class="header-item bet-result" data-t="casino-amount">
        {{ t('casinoBetsHistory.result') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const props = defineProps<{
  isSport?: boolean
}>()
</script>

<style scoped>
.header {
  display: flex;
  gap: var(--spacing-300);

  margin-bottom: var(--spacing-050);
  padding: var(--spacing-100) var(--spacing-150);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.header-item {
  flex: 10 1 0;
  min-width: 100px;
  font: var(--desktop-text-xs-medium);
  color: var(--text-quaternary);

  &.game {
    flex-grow: 15;
  }

  &.bet-rate {
    flex-grow: 5;
  }

  &.bet-amount,
  &.bet-rate,
  &.bet-result {
    text-align: right;
  }
}

.sport .game {
  flex-grow: 18;
}
</style>
