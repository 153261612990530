<template>
  <LastBetsCasinoTable
    :bets="lastBigBets"
    :is-loading="pending"
    :mobile="props.mobile"
    data-t="casino-last-big-bets-z9fm"
  />
</template>

<script setup lang="ts">
import { useLastBigBetsCasino } from '../../../../composables'
import LastBetsCasinoTable from '../tables/LastBetsCasinoTable.vue'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)
const { lastBigBets, pending } = useLastBigBetsCasino(props.mobile ? 8 : 10)
</script>
