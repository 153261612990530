<template>
  <MStSection
    class="last-bets"
    :class="platform"
    :size="headerSize === 'mobile-large' ? 'm' : 's'"
    :title="t('lastBets.lastBets')"
    :header-data-t="{ title: 'title', titleIcon: 'title-icon' }"
    :title-icon="withHeaderIcon ? 'bet' : undefined"
    :with-chevron="false"
    :data-t="`last-bets-${platform}`"
  >
    <template #desktopHeader>
      <div class="title-wrapper">
        <h2 class="title" :class="`size-${headerSize}`" data-t="title">
          <StIcon
            v-if="withHeaderIcon"
            class="bet-icon"
            name="bet"
            :size="iconSize"
            data-t="title-icon"
          />
          {{ t('lastBets.lastBets') }}
        </h2>
        <StTabs
          v-if="tabs.length > 1"
          v-model="selectedTab"
          class="last-tabs"
          type="separate"
          :tabs="tabs"
        />
      </div>
    </template>
    <template #addition>
      <StTabs
        v-if="tabs.length > 1"
        v-model="selectedTab"
        class="last-tabs"
        type="separate"
        :tabs="tabs"
      />
    </template>
    <LastBetsCasinoTab
      v-if="selectedTab === 'casino'"
      data-t="casino-table"
      :mobile="isMobile"
    />
    <LastBetsSportTab
      v-else-if="selectedTab === 'sport'"
      :mobile="isMobile"
      data-t="sport-table"
    />
    <LastBigBetsCasinoTab
      v-else-if="selectedTab === 'big-bets-casino'"
      :mobile="isMobile"
      data-t="casino-big-bets-table"
    />
    <LastBigBetsSportTab
      v-else-if="selectedTab === 'big-bets-sport'"
      :mobile="isMobile"
      data-t="sport-big-bets-table"
    />
  </MStSection>
</template>

<script setup lang="ts">
import type { Tab } from '@st/ui/components/StTabs/types'
import MStSection from '@st/ui/components/StSection/MStSection.vue'
import LastBetsCasinoTab from './parts/tabs/LastBetsCasinoTab.vue'
import LastBetsSportTab from './parts/tabs/LastBetsSportTab.vue'
import LastBigBetsCasinoTab from './parts/tabs/LastBigBetsCasinoTab.vue'
import LastBigBetsSportTab from './parts/tabs/LastBigBetsSportTab.vue'

const { t } = useI18n()

const {
  tabs,
  headerSize = 'lg',
  withHeaderIcon = true,
} = defineProps<{
  tabs: Tab[]
  headerSize?: 'lg' | '2xl' | 'mobile' | 'mobile-large'
  withHeaderIcon?: boolean
}>()

const { platform, isMobile } = usePlatform()

const selectedTab = ref(tabs[0].id)

const iconSize = computed(() => (isMobile.value ? 20 : 24))
</script>

<style scoped>
.title {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  margin: 0;

  &.size-lg {
    gap: var(--spacing-100);
    font: var(--desktop-text-lg-semibold);
  }

  &.size-2xl {
    font: var(--desktop-text-2xl-semibold);
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-150);
}

.bet-icon {
  background-color: var(--icon-secondary);
}

.last-tabs {
  overflow: auto;
}

.desktop {
  &.last-bets {
    display: block;
    gap: 0;
    padding: 0;
  }
}

.mobile {
  .last-tabs {
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
  }
}
</style>
